import * as React from 'react'
import * as styles from './Heading.module.css'
import AnchorLink from 'react-anchor-link-smooth-scroll'

interface RehypeHeadingArgs {
    id: string
    children: []
}

const HeadingFactory = (type: string) => {
    return (args: RehypeHeadingArgs) => {
        const HeadingTag: any = `${type}`
        const anchorId: string = `#${args.id}`

        /**
         * Update the URL on click
         *
         * @param event
         */
        const onClick = (event: Event) => {
            window.history.pushState({}, '', `#${args.id}`)
        }

        return (
            <HeadingTag className={styles.heading} id={args.id}>
                <AnchorLink
                    onClick={onClick}
                    href={anchorId}
                    className={styles.link}
                >
                    &nbsp;
                </AnchorLink>
                {args.children}
            </HeadingTag>
        )
    }
}

export default HeadingFactory
