import * as React from 'react'
import styles from './Meta.module.css'

interface PostNodeDate {
    node: {
        published_at: string
    }
}

export default class PostMeta extends React.Component<PostNodeDate, {}> {
    public render() {
        return (
            <div className={styles.meta}>
                {this.props.node.published_at}
                <span>/</span>by Dave Macaulay
            </div>
        )
    }
}
