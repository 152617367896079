import * as React from 'react'

interface RehypeAnchorArgs {
    href: string
    className: string
    children: string
}

const Link = (args: RehypeAnchorArgs) => {
    return (
        <a
            href={args.href}
            className={args.className}
            target="_blank"
            rel="noopener"
        >
            {args.children}
        </a>
    )
}

export default Link
