import * as React from 'react'
import { getImageSize } from './imageSize'

interface RehypePhotoArgs {
    src: string
    className: string
    children: undefined
    alt: string
}

export default class extends React.Component<RehypePhotoArgs, {}> {
    public render() {
        let placeholderHeight = null
        // @todo fix weird bitnami URL thing
        const src = this.props.src.replace(
            'http://cms.davemacaulay.com:80/',
            'https://cms.davemacaulay.com/'
        )
        if (typeof window !== 'undefined') {
            const imageSize = getImageSize(src)
            // If the window is above 760 the post body is always 720, otherwise it's window width - 40px padding
            const postBodyWidth =
                window.innerWidth > 760 ? 720 : window.innerWidth - 40
            placeholderHeight = imageSize.height
            if (imageSize.width > postBodyWidth) {
                const ratio = imageSize.height / imageSize.width
                placeholderHeight = postBodyWidth * ratio
            }
        }

        return (
            // @ts-ignore
            <img
                src={src}
                loading="lazy"
                alt={this.props.alt}
                height={placeholderHeight}
            />
        )
    }
}
