let imageSizes: ImageSize[] = []

/**
 * Set all image sizes for the current entity
 *
 * @param sizes
 */
export function setImageSizes(sizes: ImageSize[]) {
    imageSizes = sizes
}

/**
 * Retrieve image size data from the registry
 *
 * @param src
 */
export function getImageSize(src: string) {
    return imageSizes.find((image: ImageSize) => {
        return image.url == src
    })
}

export interface ImageSize {
    hUnits: string
    height: number
    length: number
    mime: string
    type: string
    url: string
    wUnits: string
    width: number
}
