import * as React from 'react'
import Photo from './Html/Photo'
import Link from './Html/Link'
import styles from './Html.module.css'

import * as unified from 'unified'
import rehypeParse from 'rehype-parse'
import rehypeReact from 'rehype-react'
import rehypeFormat from 'rehype-format'
import rehypeHtml from 'rehype-stringify'
import { ImageSize, setImageSizes } from './Html/imageSize'
import Heading from './Html/Heading'

const htmlProcessor = unified()
    .use(rehypeParse, { fragment: true })
    .use(rehypeFormat)
    .use(rehypeHtml)
    .use(rehypeReact, {
        createElement: React.createElement,
        components: {
            img: Photo,
            a: Link,
            h2: Heading('h2'),
            h3: Heading('h3'),
            h4: Heading('h4'),
            h5: Heading('h5'),
        },
    })

interface PostView {
    html: string
    imageSizes: ImageSize[]
}

export default class extends React.Component<PostView, {}> {
    constructor(props: PostView, context: any) {
        super(props, context)
        setImageSizes(this.props.imageSizes)
    }
    public render() {
        return (
            <div className={styles.postHtml}>
                {htmlProcessor.processSync(this.props.html).contents}
            </div>
        )
    }
}
