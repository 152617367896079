import * as React from 'react'
import { Link, graphql } from 'gatsby'
import { DiscussionEmbed } from 'disqus-react'
import tocbot from 'tocbot'
import { RefObject } from 'react'

import styles from './Post.module.css'

import Helmet from 'react-helmet'
import Layout from '../Layout'
import PostMeta from './Post/Meta'
import PostHtml from './Post/Html'
import { ImageSize } from './Post/Html/imageSize'

export default class extends React.Component<PostView, PostState> {
    tocElement: RefObject<HTMLDivElement>

    constructor(props: PostView, context: any) {
        super(props, context)
        this.tocElement = React.createRef()
        this.state = {
            showScroll: false,
        }
    }

    public componentDidMount() {
        const contentSelector = '#post-body'
        const headingSelector = 'h2, h3, h4, h5'
        const content = document.querySelectorAll(contentSelector)
        if (content.length > 0) {
            const headings = content.item(0).querySelectorAll(headingSelector)
            if (headings.length > 1) {
                tocbot.init({
                    tocSelector: '#table-of-contents',
                    contentSelector,
                    headingSelector,
                })
            } else {
                this.tocElement.current.style.display = 'none'
            }
        } else {
            this.tocElement.current.style.display = 'none'
        }

        // Scroll the user to their destination
        if (window.location.hash !== '') {
            setTimeout(function() {
                const ele = document.getElementById(
                    window.location.hash.slice(1)
                )
                if (ele) {
                    const offsetTop =
                        ele.getBoundingClientRect().top +
                        window.pageYOffset -
                        30
                    window.scroll({
                        top: offsetTop,
                        behavior: 'smooth',
                    })
                }
            }, 100)
        }

        if (typeof window !== 'undefined') {
            const windowHeight = window.innerHeight
            window.addEventListener('scroll', () => {
                if (
                    window.scrollY > windowHeight &&
                    this.state.showScroll === false
                ) {
                    this.setState({
                        showScroll: true,
                    })
                } else if (
                    window.scrollY < windowHeight &&
                    this.state.showScroll === true
                ) {
                    this.setState({
                        showScroll: false,
                    })
                }
            })
        }
    }

    public componentDidUpdate() {
        tocbot.refresh()
    }

    public render() {
        const post = this.props.data.ghostPost
        const disqusShortname = 'dave-macaulay'
        const disqusConfig = {
            identifier: post.id,
            title: post.title,
        }
        const ogImageUrl = `http://image.thum.io/get/ogImage/https://davemacaulay.com/${post.slug}/`
        const scrollToTop = () => {
            var cosParameter = window.scrollY / 2,
                scrollCount = 0,
                oldTimestamp = performance.now()
            function step(newTimestamp: number) {
                scrollCount += Math.PI / (1000 / (newTimestamp - oldTimestamp))
                if (scrollCount >= Math.PI) window.scrollTo(0, 0)
                if (window.scrollY === 0) return
                window.scrollTo(
                    0,
                    Math.round(
                        cosParameter + cosParameter * Math.cos(scrollCount)
                    )
                )
                oldTimestamp = newTimestamp
                window.requestAnimationFrame(step)
            }
            window.requestAnimationFrame(step)
        }
        const showScrollActiveClass = this.state.showScroll
            ? styles.scrollTopActive
            : ''
        return (
            <Layout>
                <Helmet>
                    <title>{post.title}</title>
                    <meta name="description" content={post.excerpt} />
                    <meta property="og:type" content="article" />
                    <meta property="og:title" content={post.title} />
                    <meta property="og:description" content={post.excerpt} />
                    <meta property="og:image" content={ogImageUrl} />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:creator" content="@HelloMacaulay" />
                    <meta name="twitter:title" content={post.title} />
                    <meta name="twitter:description" content={post.excerpt} />
                    <meta name="twitter:image" content={ogImageUrl} />
                </Helmet>
                <div className={styles.post}>
                    <div className={styles.postWrapper}>
                        <div id="post-body" className={styles.postBody}>
                            <PostMeta node={post} />
                            <h1 className={styles.postTitle}>{post.title}</h1>
                            <PostHtml
                                html={post.html}
                                imageSizes={this.props.pageContext.imageSizes}
                            />
                        </div>
                        <DiscussionEmbed
                            shortname={disqusShortname}
                            config={disqusConfig}
                        />
                        <div className={styles.back}>
                            <Link to="/blog/">
                                <span>Back</span>.
                            </Link>
                        </div>
                    </div>
                    <div ref={this.tocElement} className={styles.postToc}>
                        <div id="table-of-contents" className="toc" />
                    </div>
                </div>
                <a
                    onClick={scrollToTop}
                    className={styles.scrollTop + ' ' + showScrollActiveClass}
                >
                    Scroll to top
                </a>
            </Layout>
        )
    }
}

interface PostView {
    data: {
        ghostPost: {
            slug: string
            html: string
            id: string
            title: string
            published_at: string
            excerpt: string
        }
        site: {
            siteMetadata: {
                title: string
            }
        }
    }
    pageContext: {
        imageSizes: ImageSize[]
    }
}

interface PostState {
    showScroll: boolean
}

export const postQuery = graphql`
    query($slug: String!) {
        ghostPost(slug: { eq: $slug }) {
            id
            slug
            title
            published_at(formatString: "MMMM DD, YYYY")
            html
            excerpt
        }
        site {
            siteMetadata {
                title
            }
        }
    }
`
